import { css } from '@emotion/react';

// Avenir
import AvenirBold from '../fonts/AvenirNextCondensed-Bold.otf';
import AvenirHeavy from '../fonts/Avenir-Heavy.otf';
import AvenirRoman from '../fonts/Avenir-Roman.otf';

export const fonts = css`
  @font-face {
    font-family: 'Avenir 55';
    font-weight: 500;
    src: url(${AvenirRoman});
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir Heavy';
    font-weight: 600;
    src: url(${AvenirHeavy});
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir Next Condensed';
    font-weight: 700;
    src: url(${AvenirBold});
    font-display: swap;
  }
`;
